// 创建企业
// mxy
<template>
  <div class="main">
    <a-spin :spinning="spinning">
      <a-form
        class="user-layout-login"
        :model="register"
        ref="formRef"
        :rules="rules"
        autocomplete="off"
      >
        <a-tabs :tabBarStyle="{ textAlign: 'center', borderBottom: 'unset' }">
          <a-tab-pane key="tab1" tab="创建企业">
            <a-form-item required name="name" :wrapper-col="{ span: 24 }">
              <a-input
                size="large"
                :maxLength="30"
                type="text"
                v-model:value="register.name"
                placeholder="公司名称"
              >
              </a-input>
            </a-form-item>
            <a-form-item required name="position" :wrapper-col="{ span: 24 }">
              <a-select
                placeholder="所属行业"
                size="large"
                v-model:value="register.position"
                @change="handleChange"
              >
                <a-select-option
                  v-for="r in externalGenreList"
                  :key="r.linkKey"
                  :value="r.linkKey"
                  >{{ r.name }}</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-cascader
                :options="CityInfo"
                placeholder="请选择地址"
                size="large"
                v-model:value="register.selectedOptions"
                @change="onChange"
              />
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 24 }">
              <a-input
                size="large"
                :maxLength="30"
                type="text"
                v-model:value="register.address"
                placeholder="详细地址"
              >
              </a-input>
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
        <a-form-item class="bottom" :wrapper-col="{ span: 24 }">
          <div class="flex justify-between">
            <div>
              <router-link to="/account/Jion">
                <span class="font-size">已有企业？点击加入</span>
              </router-link>
            </div>
            <div>
              <span class="font-size handleLogout" @click="handleLogout"
                >退出登录</span
              >
            </div>
          </div>
        </a-form-item>
        <a-form-item style="margin-top: 24px" :wrapper-col="{ span: 24 }">
          <a-button
            type="primary"
            html-type="submit"
            size="large"
            class="login-button"
            @click="createOK"
            :loading="state.loginBtn"
            :disabled="state.loginBtn"
          >
            确定
          </a-button>
        </a-form-item>
      </a-form>
      <div class="on_trial" @click="trial">试用体验版本，立即体验~</div>
    </a-spin>
  </div>
</template>

<script>

import api from '@/api/API'
import { ref } from 'vue'
import axios from 'axios'
export default {
  name: 'account-register',
  data () {
    return {
      spinning: false,
      CityInfo: [], // 地区数据
      selectedOptions: '',
      rules: {
        name: [
          {
            required: true,
            message: '请输入公司名称',
            trigger: 'blur',
          },
        ],
        position: [
          {
            required: true,
            message: '请选择所属行业',
            trigger: 'blur',
          },
        ],
      }, // 验证规则
      externalGenreList: [],
      register: {
        name: '',
        position: ref(undefined),
        address: '',
        selectedOptions: [],
        city: '',
        erae: '',
        minerae: '',
      },
      formState: {
        userName: '',
        password: '',
      },
      state: {
        loginBtn: false,
        loginType: 0,
        smsSendBtn: false,
      },
      industryId: '',
      industryIdName: '',
      location: [],
      locationAddress: '',
      LocationIndex: '',
    }
  },
  async mounted () {
    await this.handleFocus()
    await axios.get('/api/city.json').then(data => {
      this.CityInfo = data.data
    })
    if (this.$route.params && this.$route.params.sign) {
      this.$message.warn('当前用户在威智造没有创建或加入的企业，请创建或加入企业')
      // 回显数据
      const {
        firmName,
        industry,
        addressProvince,
        addressCity,
        addressRegion,
        address,
      } = this.$route.params
      this.register.address = address
      this.register.name = firmName
      this.externalGenreList.forEach(cur => {
        if (cur.name === industry) {
          this.register.position = cur.linkKey
          this.industryId = cur.linkKey
          this.industryIdName = industry
        }
      })
      this.locationCallBack(addressProvince, addressCity, addressRegion)
    }
  },
  methods: {
    // 试用
    trial () {
      this.$router.push({ path: '/account/Trial' })
    },
    // 退出登录
    handleLogout () {
      this.spinning = true
      window.location.href = '/account/login'
      window.localStorage.clear()
      // 清除cookie
      window.document.cookie = 'userName' + '=' + '' + ';path=/;expires='
      window.document.cookie = 'userPwd' + '=' + '' + ';path=/;expires='
      history.pushState(null, null, document.URL)
      window.addEventListener(
        'popstate',
        function (e) {
          history.pushState(null, null, document.URL)
        },
        false,
      )
    },
    // 选择地址
    onChange (value, selectedOptions) {
      this.location = []
      this.LocationIndex = value.toString()
      selectedOptions.forEach(element => {
        this.location.push(element.label)
        this.locationAddress = (this.location.toString()).replace(/,/g, '')
      })
      if (value.length === 0) {
        this.locationAddress = ''
      }
      this.register.city = this.register.selectedOptions[0]
      this.register.erae = this.register.selectedOptions[1]
      this.register.minerae = this.register.selectedOptions[2]
    },
    // 选择所属行业
    handleChange (value, option) {
      this.industryId = value
      option.children.forEach(element => {
        this.industryIdName = element.children
      })
    },
    // 获取行列表
    async handleFocus () {
      await api
        .get('/api/app/sys-industry/industry-select-list')
        .then(({ data }) => {
          this.externalGenreList = data
        })
        .catch(err => {
          console.log(err)
          // this.$message.error('获取单位信息失败 ')
        })
    },
    // 确定创建
    createOK () {
      this.$refs.formRef.validate().then(() => {
        this.state.loginBtn = true
        const obj = {
          name: this.register.name,
          location: this.locationAddress,
          LocationIndex: this.LocationIndex,
          industryId: this.industryId,
          industryName: this.industryIdName,
          detailAddress: this.register.address,
        }
        api
          .post('/api/app/account/tenant-enterprise-info', obj)
          .then(({ data }) => {
            this.state.loginBtn = false
            this.$message.success('创建成功！')
            this.spinning = true
            this.switchTenant(data)
          })
          .catch(err => {
            this.state.loginBtn = false
            console.log(err)
            // this.$message.error('创建失败' + err)
          })
      })
        .catch(error => {
          console.log(error)
        })
    },
    switchTenant (tenantId) {
      api
        .post('/api/app/account/switch-tenant/{' + tenantId + '}')
        .then(({ data }) => {
          this.$store.dispatch('account/setToken', data.rawData)
          this.$store.dispatch('account/setTenant', {
            tenantId: data.tenantId,
          })
          this.$router.push({ path: '/WorkManagement/WorkBench' })
          this.spinning = false
        })
        .catch(err => {
          this.spinning = false
          this.$message.error('登录异常，请重新登录')
          console.log(err)
        })
    },
    // 根据label属性回显省市区联动
    locationCallBack (addressProvince, addressCity, addressRegion) {
      if (!addressProvince || !addressCity) {
        return
      }
      addressRegion = addressRegion || '其他'
      const curLocationIndex = []
      let locationArray = this.CityInfo
      const curLocationNames = [addressProvince, addressCity, addressRegion]
      for (let i = 0; i < curLocationNames.length; i++) {
        let curLocation = null
        for (let j = 0; j < locationArray.length; j++) {
          const cur = locationArray[j]
          if (cur.label === curLocationNames[i]) {
            curLocationIndex.push(cur.value)
            curLocation = cur
            break
          }
        }
        if (!curLocation || !curLocation.children || curLocation.children.length === 0) {
          break
        }
        locationArray = curLocation.children
      }
      if (curLocationIndex.length === 3) {
        this.register.selectedOptions = curLocationIndex
        this.LocationIndex = curLocationIndex.toString()
        this.locationAddress = curLocationNames.toString().replace(/,/g, '')
        this.register.city = this.register.selectedOptions[0]
        this.register.erae = this.register.selectedOptions[1]
        this.register.minerae = this.register.selectedOptions[2]
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/common.scss";
.main {
  padding: 60px;

  .ant-form-item-control-wrapper {
    width: 100% !important;
  }
  .user-layout-login {
    label {
      font-size: 14px;
    }
    .getCaptcha {
      display: block;
      width: 100%;
      height: 40px;
    }

    .forge-password {
      font-size: 14px;
    }

    button.login-button {
      padding: 0 15px;
      font-size: 16px;
      height: 40px;
      width: 100%;
      background-color: #134ccf;
      color: #fff;
    }
    .user-login-other {
      text-align: left;
      margin-top: 24px;
      line-height: 22px;

      .item-icon {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.2);
        margin-left: 16px;
        vertical-align: middle;
        cursor: pointer;
        transition: color 0.3s;

        &:hover {
          color: #1890ff;
        }
      }

      .register {
        float: right;
      }
    }
  }
  .bottom {
    margin-top: -20px;
    text-align: left;
    font-size: 12px;
    .handleLogout {
      cursor: pointer;
    }
  }
  .on_trial {
    color: #134ccf;
    cursor: pointer;
    text-align: center;
  }
}
::v-deep(.ant-tabs-tab:hover) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab-active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-nav .ant-tabs-tab:active) {
  color: #134ccf;
}
::v-deep(.ant-tabs-ink-bar) {
  background-color: #134ccf;
}
::v-deep(.ant-btn:hover) {
  border-color: #134ccf;
  opacity: 0.7;
  color: #fff;
}
::v-deep(.ant-btn:focus) {
  border-color: #134ccf;
  box-shadow: none;
}
::v-deep(.ant-input:hover, .ant-input:focus) {
  border-color: #134ccf;
}
::v-deep(.ant-select-focused:not(.ant-select-disabled)) {
  border-color: #134ccf;
}
::v-deep(.ant-select:not(.ant-select-disabled):hover) {
  border-color: #134ccf;
}
::v-deep(.ant-select:not(.ant-select-disabled):focus) {
  border-color: #134ccf;
}
::v-deep(.ant-cascader-picker-label:hover + .ant-cascader-input) {
  border-color: #134ccf;
}
::v-deep(.ant-cascader-picker
    .ant-cascader-picker-large.ant-cascader-picker-focused) {
  border-color: #134ccf;
}
// ::v-deep(.ant-select-singel:not(.ant-select-customize-input) .ant-select-selector) {
//   border-color: #134ccf;
// }
// ::v-deep(.ant-input-affix-wrapper:hover, .ant-input-affix-wrapper:focus) {
//   border-color: #134ccf;
// }
::v-deep(.ant-input-focused) {
  border-color: #134ccf;
  box-shadow: none;
}
</style>
